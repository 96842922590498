<template>
    <div
        v-intersect="onIntersect"
        class="ad-set-previews">
        <div class="camp">
            <div class="camp__heading">
                <div
                    v-if="!campaign && !campaignError"
                    class="">
                    <styled-loader :size="20" />
                </div>
                <div
                    v-else-if="campaignError"
                    class="camp_error">
                    {{ campaignError }}
                </div>
                <div
                    v-else
                    class="camp__holder">
                    <div class="camp__icon">
                        <icon
                            size="20"
                            name="folder" />
                    </div>
                    <div class="camp__text">
                        <div class="camp__subtitle">
                            Campaign:
                        </div>
                        <div class="camp__desc">
                            {{ campaign.name }}
                        </div>
                    </div>
                    <div class="camp__active">
                        <span
                            class="circle"
                            :class="{
                                'active': campaign.status === 'ACTIVE'
                            }" />
                    </div>
                </div>
                <div class="camp__divider">
                    <icon
                        size="16"
                        color="blue"
                        name="chevron-right" />
                </div>
                <div
                    v-if="loadingAdSet"
                    class="">
                    <styled-loader :size="20" />
                </div>
                <div
                    v-else-if="error"
                    class="camp_error">
                    {{ error }}
                </div>
                <div
                    v-else-if="activeAdSet"
                    class="camp__holder">
                    <div class="camp__icon">
                        <icon
                            size="20"
                            name="adset" />
                    </div>
                    <div class="camp__text">
                        <div class="camp__subtitle">
                            Adset:
                        </div>
                        <div class="camp__desc">
                            {{ activeAdSet.name }}
                        </div>
                    </div>
                    <div class="camp__active">
                        <span
                            class="circle"
                            :class="{
                                'active': activeAdSet.status === 'ACTIVE'
                            }" />
                    </div>
                </div>
            </div>
        </div>
        <div
            :class="[
                'preview-wrapper',
                `preview-wrapper-${channel}`
            ]">
            <ad
                v-for="ad in activeAds"
                :key="ad.external_id"
                :class="[
                    'ad-preview',
                    `ad-preview-${channel}`
                ]"
                :ad-account-id="adAccountId"
                :ad-id="ad.external_id"
                :channel="channel"
                :placement="placement"
                :ad-status="adStatus"
                :dealer-id="dealerId"
                :agency-id="agencyId"
                :ad-preview-key="adPreviewKey" />
        </div>
        <template v-if="!ads">
            <div
                v-if="loadingAdsPage"
                class="pa-5 text-xs-center">
                <styled-loader
                    :size="30"
                    label="Loading next ads page..." />
            </div>
            <div
                v-if="Boolean(nextPage)"
                :key="loadedAds.length"
                v-intersect="onNextPage"
                class="next-page" />
            <div
                v-if="loadedAds.length === 0"
                :class="[
                    'ad-preview-placeholder',
                    'ad-preview',
                    `ad-preview-${channel}`
                ]" />
        </template>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import StyledLoader from '@/components/globals/StyledLoader';
import Ad from './Ad'; 
import { PLATFORM_FACEBOOK, PLATFORM_PINTEREST } from '@/helpers/globals';

export default {
    components: {
        Ad,
        Icon,
        StyledLoader,
    },
    props: {
        adAccountId: {
            type: [Number, String],
            required: true
        },
        campaignError: {
            type: String,
            default: ''
        },
        campaign: {
            type: Object,
            default: null
        },
        preLoaded: {
            type: Boolean,
            required: true
        },
        adSetId: {
            type: [Number, String],
            required: true
        },
        adSet: {
            type: Object,
            default: null
        },
        ads: {
            type: Array,
            default: null
        },
        channel: {
            type: String,
            required: true
        },
        placement: {
            type: String,
            required: true
        },
        adStatus: {
            type: String,
            required: true
        },
        dealerId: {
            type: Number,
            required: true
        },
        agencyId: {
            type: Number,
            required: true
        },
        adPreviewKey: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            hasIntersected: false,
            loadingAdSet: false,
            loadedAdSet: null,
            adCount: null,
            loadingAdsCount: false,
            loadingAdsPage: false,
            loadedAds: [],
            nextPage: null,
            error: '',
        };
    },
    computed: {
        activeAdSet() {
            if (this.preLoaded) {
                return this.adSet;
            }

            return this.loadedAdSet;
        },
        activeAds() {
            if (this.ads) {
                return this.ads;
            }

            return this.loadedAds;
        },
        pageSize() {
            if ([PLATFORM_PINTEREST, PLATFORM_FACEBOOK].includes(this.channel)) {
                return 10;
            }

            // @todo Remove high pagination for other channels once
            // the server properly handles filtering, etc
            return 1000;
        }
    },
    methods: {
        onNextPage() {
            if (!this.loadingAdsPage && this.nextPage) {
                this.getAdsPage();
            }
        },
        onIntersect() {
            if (!this.hasIntersected) {
                this.init();
            }
            this.hasIntersected = true;
        },
        async init() {
            if (!this.preLoaded) {
                this.getAdSet();
            }

            if (!this.ads) {
                this.getAdsPage();
            }
        },
        async getAdsPage() {
            try {
                this.loadingAdsPage = true;

                console.log(`Retrieving ads page for ad set ${this.adSetId}`);

                const config = {
                    dealerId: this.dealerId,
                    platform: this.channel,
                    pageSize: this.pageSize,
                    adSetIds: [ this.adSetId ],
                    key: this.adPreviewKey,
                    page: this.nextPage,
                };

                const response = await this.$apiRepository.getDealerPlatformAdsPreview(config);

                this.loadedAds = this.loadedAds.concat(response.data.data);

                // If there's another page to load track it
                this.nextPage = response.data?.meta?.next_page || null;

            } catch(error) {
                this.error = `Error loading ads page ${this.nextPage} for ad set: ${this.adSetId}`;
                console.log(this.error, error.response || error);
            } finally {
                this.loadingAdsPage = false;
            }
        },
        async getAdSet() {
            try {
                this.loadingAdSet = true;
                this.error = '';

                const response = await this.$apiRepository.getDealerPlatformAdSetPreview({
                        dealerId: this.dealerId,
                        platform: this.channel,
                        adSetId: this.adSetId,
                        key: this.adPreviewKey
                    });

                this.loadedAdSet = response.data;
            } catch(error) {
                this.error = `Error loading ad set ${this.adSetId}`;
                console.log(this.error, error.response || error);
            } finally {
                this.loadingAdSet = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.ad-set-previews {
    background:#F4F5F6;
}
.camp_error {
    color: $error-500;
}
.camp__heading {
    display: flex;
    background: white;
    padding: 15px 30px 15px 35px;
    border-bottom: 1px solid $gray-light;
    border-right: 1px solid $gray-light;
    justify-content: flex-start;
        @media only screen and (max-width: 959px) {
            flex-direction: column;
            padding: 0;
            border-right:none;
            border-bottom: none;
        }
}
.camp__holder{
    display: flex;
    @media only screen and (max-width: 959px) {
        border-bottom: 1px solid $gray-light;
        padding: 15px 30px 15px 35px;
        width: 100%;
    }
    @media only screen and (max-width: 599px) {
        border-bottom: 1px solid $gray-light;
        padding: 15px;
    }
}
.camp__icon{
    margin-right: 15px;
}
.camp__text{
    display: flex;
    align-items: center;
    @media only screen and (max-width: 599px) {
        align-items: flex-start;
    }
}
.camp__subtitle{
    color:#00A2EA;
    font-size: 14px;
}
.camp__desc{
    font-size: 13px;
    margin-left: 10px;
}
.camp__active{
    display: flex;
    align-items: center;
    margin-left:15px;
}
.camp__divider{
    margin-left:15px;
    margin-right: 15px;
    margin-top:-1px;
    @media only screen and (max-width: 959px) {
        display: none;
    }
}

.preview-wrapper{
    padding: 30px 0;
    text-align: center;
    @media (min-width: $bp-sm) {
        padding: 30px;
        text-align: left;
    }
}

.preview-wrapper-facebook {
    display: flex;
    flex-wrap: wrap;
}

.ad-preview {
    display: inline-block;
    text-align: left; /* Override the wrapper */
    min-width: 350px;
    min-height: 600px;
    vertical-align: top;
    @media (min-width: $bp-sm) {
        min-height: 700px;
        min-width: 420px;
    }
    &.ad-preview-facebook {
        min-width: 100%;
        @media (min-width: $bp-sm) {
            min-width: 500px;
        }
    }
}

.next-page {
    height: 100px;
    width: 100%;
}
</style>
