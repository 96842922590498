<template>
    <div id="ad-preview-page">
        <div class="header">
            <div class="logo">
                <img
                    :src="$brand.asset_urls.navbar_logo"
                    alt="Agency logo">
            </div>
        </div>

        <!-- @todo Check out the original frontend PR (?) to add alternate view -->
        <preview-with-grid />
    </div>
</template>

<script>
import PreviewWithGrid from '@/components/playbook-management/ad-preview/PreviewWithGrid';
export default {
    name: 'AdPreview',
    title: 'Ad Preview',
    components: {
        PreviewWithGrid,
    },
    data() {
        return { };
    }
};
</script>

<style lang="scss">
@import "@/assets/scss/playbook-management.scss";
body{
    overflow: visible;
}
</style>
