<template>
    <div
        class="styled-checkboxes"
        :class="{
            'inline': inline
        }">
        <styled-checkbox
            v-for="item in items"
            :key="item.value"
            :label="item.text"
            :checkmark-scale="checkmarkScale"
            :checked="value.includes(item.value)"
            :square="square"
            inline
            @update:checked="onItemChecked(item, $event)" />
    </div>
</template>
<script>
import StyledCheckbox from '@/components/globals/StyledCheckbox';

export default {
    components: {
        StyledCheckbox
    },
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        items: {
            type: Array,
            required: true,
            validate(value) {
                return (
                    value?.[0]?.text &&
                    value?.[0]?.value
                );
            }
        },
        checkmarkScale: {
            type: [String,Number],
            default: 1
        },
        square: {
            type: Boolean,
            default: false
        },
        inline: {
            type: Boolean,
            default: false
        },
    },
    methods: {
        onItemChecked(item, isChecked) {
            const newValue = [...this.value];

            if (isChecked && !newValue.includes(item.value)) {
                newValue.push(item.value);
            }

            if (!isChecked && newValue.includes(item.value)) {
                const index = newValue.indexOf(item.value);
                newValue.splice(index, 1);
            }
            this.$emit('input', newValue);
        }
    }
};
</script>

<style lang="scss" scoped>
.inline {
    display: flex;
    align-items: center;
}
.styled-checkbox.inline {
    margin-bottom: 0;
    margin-right: 20px;
    &:last-child {
        margin: 0;
    }
}
</style>