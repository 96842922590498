<template>
    <div class="main">
        <div id="grid-preview">
            <filtration
                :platforms="Object.keys(storage)"
                :filters.sync="filters"
                @update:filters="handleFilterUpdate"
                @reset="handleFilterReset" />

            <div
                v-if="!loadingAdPreviews"
                class="panel-wrapper">
                <div class="leftpan">
                    <v-alert
                        class="ma-4"
                        :value="adPreviewError"
                        type="error">
                        {{ adPreviewError }}
                    </v-alert>

                    <template v-for="[channel, channelPreviews] in activeChannelEntries">
                        <channel
                            v-if="channelPreviews.ad_account_id"
                            :key="channel"
                            :channel="channel"
                            :placement="filters.placement"
                            :ad-status="filters.adStatus"
                            :ad-account-id="channelPreviews.ad_account_id"
                            :campaigns="channelPreviews.campaigns"
                            :dealer-id="dealerId"
                            :agency-id="agencyId"
                            :ad-preview-key="adPreviewKey" />
                    </template>
                </div>
            </div>
            <div
                v-else
                class="pa-5 text-center">
                <styled-loader
                    :size="30"
                    label="Loading ad previews..." />
            </div>
        </div>
    </div>
</template>

<script>
import Channel from './Channel';
import Filtration from './Filtration';
import axios from 'axios';
import syncQuery from '@/mixins/sync-query';
import StyledLoader from '@/components/globals/StyledLoader';
import { FACEBOOK_AD_PLACEMENT_MOBILE_FEED_STANDARD } from '@/helpers/globals';

const stateParams = {
    platforms: 'platforms',
    adStatus: 'ad_status',
    placement: 'placement',
};

export default {
    components: {
        Channel,
        Filtration,
        StyledLoader
    },
    mixins: [syncQuery],
    data() {
        return {
            storage: {},
            placement: FACEBOOK_AD_PLACEMENT_MOBILE_FEED_STANDARD,
            agencyId: null,
            dealerId: null,
            adPreviewKey: null,
            loadingAdPreviews: false,
            adPreviewError: '',
            platforms: [],
            filters: {}
        };
    },
    computed: {
        activeChannelEntries() {
            return Object.entries(this.storage)
                    .filter(([platform]) => (
                        this.filters.platforms.includes(platform)
                    ));
        }
    },
    created() {
        this.readUrlParams();
        this.fetchPreviews();
    },
    methods: {
        async fetchPreviews() {
            try {
                this.adPreviewError = '';
                this.loadingAdPreviews = true;

                axios.defaults.headers.common['BB-Token'] = process.env.VUE_APP_BB_TOKEN;
                const response = await axios.get(process.env.VUE_APP_MVP_API_PUBLIC_BASE_URI + '/data-storage/ad_previews:' + this.$route.query.id);
                this.storage = response.data.data.previews;
                this.agencyId = response.data.data.agency_id;
                this.dealerId = response.data.data.dealer_id;
                this.adPreviewKey = response.data.key;
                if (this.hasUrlParams()) {
                    this.readUrlParams();
                } else {
                    this.setFilters();
                }
            } catch(error) {
                this.adPreviewError = 'An error occurred fetching ad previews. Please try again or contact support if the problem continues.';
                console.error('Error fetching previews,', error.response || error);
            } finally {
                this.loadingAdPreviews = false;
            }
        },
        setFilters() {
            this.filters = {
                platforms: Object.keys(this.storage),
                adStatus: '',
                placement: FACEBOOK_AD_PLACEMENT_MOBILE_FEED_STANDARD,
            };
        },
        handleFilterUpdate() {
            this.setUrlParams();
        },
        handleFilterReset() {
            this.setFilters();
        },
        setUrlParams() {
            const query = { ...this.$route.query };
            for (const property in stateParams) {
                const param = stateParams[property];
                query[param] = this.filters[property];
            }

            // Replace versus push since we don't support navigation
            const { href } = this.$router.resolve({ query });
            window.history.replaceState({}, '', href);
        },
        hasUrlParams() {
            return Object.values(stateParams).some(param => (
                this.$route.query[param]
            ));
        },
        readUrlParams() {
            const filters = {};
            for (const property in stateParams) {
                const param = stateParams[property];
                filters[property] = this.$route.query[param];
            }

            this.filters = filters;
        }
    }
};
</script>