<template>
    <div
        class="title-wrapper">
        <h2>Omni Ad Previews</h2>
        <div v-outside-click="() => filtrationVisible = false">
            <action-button
                icon="settings"
                width="25"
                height="25"
                @click="filtrationVisible = !filtrationVisible" />
            <!-- filtration -->
            <div
                v-if="filtrationVisible"
                class="filtration">
                <div class="filtration__topbar">
                    <div class="filtration__title">
                        Filter your results
                    </div>
                    <button
                        class="filtration__reset"
                        @click="handleReset">
                        Reset
                    </button>
                </div>
                <div class="filtration__selects">
                    <checkboxes-field
                        v-model="selectedPlatforms"
                        :options="platformOptions"
                        label="Channel(s)"
                        class="mb-4" />
                    <v-select
                        v-if="selectedPlatforms.includes(PLATFORM_FACEBOOK)"
                        v-model="selectedPlacement"
                        :items="FACEBOOK_AD_PLACEMENTS"
                        item-text="name"
                        item-value="apiName"
                        label="Facebook Placement"
                        class="styled-field mb-4" />
                    <v-select
                        v-model="selectedAdStatus"
                        :items="PLATFORM_STATUS_OPTIONS"
                        item-text="label"
                        label="Ad Status"
                        class="styled-field mb-4" />
                    <div class="filtration__buttons">
                        <button
                            class="filtration__cancel"
                            @click="filtrationVisible = false">
                            Cancel
                        </button>
                        <button
                            class="filtration__submit"
                            @click="handleSubmit">
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- end of filtration -->
    </div>
</template>

<script>
import CheckboxesField from '@/components/globals/fields/CheckboxesField';
import ActionButton from '@/components/globals/ActionButton';
import { getChannelByPlatformKey } from '@/components/onboarding/channel-setup/channels';
import {
    FACEBOOK_AD_PLACEMENTS,
    PLATFORM_FACEBOOK,
    PLATFORM_STATUS_OPTIONS
} from '@/helpers/globals';

export default {
    components: {
        ActionButton,
        CheckboxesField,
    },
    props: {
        platforms: {
            type: Array,
            required: true
        },
        filters: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            filtrationVisible: false,
            selectedPlatforms: [],
            selectedAdStatus: '',
            selectedPlacement: '',
            PLATFORM_STATUS_OPTIONS,
            FACEBOOK_AD_PLACEMENTS,
            PLATFORM_FACEBOOK
        };
    },
    computed: {
        platformOptions() {
            return this.platforms
                .map(platform => {
                    const channel = getChannelByPlatformKey(platform);
                    return {
                        text: channel.name,
                        value: platform
                    };
                });
        },
        filtersFormatted() {
            return {
                platforms: this.selectedPlatforms,
                adStatus: this.selectedAdStatus,
                placement: this.selectedPlacement
            };
        }
    },
    watch: {
        filters: {
            immediate: true,
            handler(value) {
                this.selectedPlatforms = value.platforms;
                this.selectedAdStatus = value.adStatus;
                this.selectedPlacement = value.placement;
            }
        }
    },
    methods: {
        handleSubmit() {
            this.$emit('update:filters', this.filtersFormatted);
            this.filtrationVisible = false;
        },
        handleReset() {
            this.$emit('reset');
            this.filtrationVisible = false;
        }
    }
};
</script>

