<template>
    <div
        v-intersect="onIntersect"
        :style="{
            minHeight: '400px'
        }">
        <ad-set
            v-for="adSet in activeAdSets"
            :key="adSet.external_id"
            :ad-account-id="adAccountId"
            :campaign="campaign"
            :campaign-error="error"
            :pre-loaded="!adSets"
            :ad-set-id="adSet.external_id"
            :ad-set="adSet"
            :ads="adSet.ads"
            :channel="channel"
            :placement="placement"
            :ad-status="adStatus"
            :dealer-id="dealerId"
            :agency-id="agencyId"
            :ad-preview-key="adPreviewKey" />
        <template v-if="!adSets">
            <div
                v-if="loadingAdSetPage"
                class="pa-5 text-xs-center">
                <styled-loader
                    :size="30"
                    label="Loading next ads page..." />
            </div>
            <div
                v-if="Boolean(nextPage)"
                :key="loadedAdSets.length"
                v-intersect="onNextPage"
                class="next-page" />
            <div
                v-if="loadedAdSets.length === 0"
                class="ad-set-placeholder" />
        </template>
    </div>
</template>
<script>
import StyledLoader from '@/components/globals/StyledLoader';
import AdSet from './AdSet';
import { PLATFORM_FACEBOOK, PLATFORM_PINTEREST } from '@/helpers/globals';

export default {
    components: {
        StyledLoader,
        AdSet
    },
    props: {
        adAccountId: {
            type: [Number, String],
            required: true
        },
        campaignId: {
            type: [Number, String],
            required: true
        },
        adSets: {
            type: Array,
            default: null
        },
        channel: {
            type: String,
            required: true
        },
        placement: {
            type: String,
            required: true
        },
        adStatus: {
            type: String,
            required: true
        },
        dealerId: {
            type: Number,
            required: true
        },
        agencyId: {
            type: Number,
            required: true
        },
        adPreviewKey: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            loadingCampaign: false,
            loadingAdSetPage: false,
            loadingAdSetCount: false,
            hasIntersected: false,
            campaign: null,
            adSetCount: null,
            nextPage: null,
            loadedAdSets: [],
            error: '',
        };
    },
    computed: {
        activeAdSets() {
            if (this.adSets) {
                return this.adSets;
            }

            if (this.loadedAdSets?.length) {
                return this.loadedAdSets;
            }

            return [];
        },
        pageSize() {
            if ([PLATFORM_PINTEREST, PLATFORM_FACEBOOK].includes(this.channel)) {
                return 10;
            }

            // @todo Remove high pagination for other channels once
            // the server properly handles filtering, etc
            return 1000;
        }
    },
    methods: {
        onNextPage() {
            if (!this.loadingAdSetPage && this.nextPage) {
                this.getAdSetsPage();
            }
        },
        onIntersect() {
            if (!this.hasIntersected) {
                this.init();
            }
            this.hasIntersected = true;
        },
        async init() {
            this.getCampaign();

            if (!this.adSets) {
                this.getAdSetsPage();
            }
        },
        async getAdSetsPage() {
            try {
                this.loadingAdSetPage = true;

                console.log(`Retrieving ad sets page for campaign ${this.campaignId}`);

                const config = {
                    dealerId: this.dealerId,
                    platform: this.channel,
                    pageSize: this.pageSize,
                    campaignIds: [ this.campaignId ],
                    key: this.adPreviewKey,
                    page: this.nextPage,
                };

                const response = await this.$apiRepository.getDealerPlatformAdSetsPreview(config);

                this.loadedAdSets = this.loadedAdSets.concat(response.data.data);

                // If there's another page to load track it
                this.nextPage = response.data?.meta?.next_page || null;

            } catch(error) {
                this.error = `Error loading adset page ${this.nextPage} for campaign ${this.campaignId}`;
                console.log(this.error, error.response || error);
            } finally {
                this.loadingAdSetPage = false;
            }
        },
        async getCampaign() {
            try {
                this.loadingCampaign = true;
                this.error = '';

                const response = await this.$apiRepository.getDealerPlatformCampaignPreview({
                    dealerId: this.dealerId,
                    platform: this.channel,
                    campaignId: this.campaignId,
                    key: this.adPreviewKey
                });

                this.campaign = response.data;
            } catch(error) {
                this.error = `Error loading campaign ${this.campaignId}`;
                console.log(this.error, error.response || error);
            } finally {
                this.loadingCampaign = false;
            }

        }
    }
};
</script>

<style lang="scss" scoped>
.ad-set-placeholder {
    height: 600px;
}
.next-page {
    height: 100px;
    width: 100%;
}
</style>
