<template>
    <div
        v-show="isDisplayed"
        v-intersect="onIntersect"
        :style="{
            '--iframe-width': `${currentFbPlacementData.iframeWidth}px`,
            '--iframe-height': `${currentFbPlacementData.iframeHeight}px`,
            maxWidth: (channel === 'facebook') ? `${maxFbWidth + 40}px` : 'none'
        }">
        <div
            v-if="loading && !loaded"
            class="pa-5 text-xs-center">
            <styled-loader :size="20" />
        </div>
        <div
            v-else-if="error"
            class="my-5">
            <ad-placeholder>
                <div v-html="error" />
            </ad-placeholder>
        </div>
        <template v-else-if="ad && channel === 'facebook'">
            <styled-card
                hide-toggle
                simple
                class="fb-ad-preview-container">
                <template #heading>
                    {{ ad.name }}
                    <span
                        class="circle ml-1"
                        :class="{
                            'active': ad.status === 'ACTIVE'
                        }" />
                </template>
                <template #action-buttons>
                    <v-menu offset-y>
                        <template #activator="{ on }">
                            <span v-on="on">
                                <icon
                                    size="20"
                                    name="list" />
                            </span>
                        </template>
                        <v-list>
                            <v-list-tile
                                v-for="fbPlacement in FACEBOOK_AD_PLACEMENTS"
                                :key="fbPlacement.apiName"
                                @click="currentFbPlacement = fbPlacement.apiName">
                                <v-list-tile-title>{{ fbPlacement.name }}</v-list-tile-title>
                            </v-list-tile>
                        </v-list>
                    </v-menu>
                </template>
                <div
                    v-if="loading && loaded"
                    class="pa-5 text-xs-center">
                    <styled-loader :size="20" />
                </div>
                <div
                    v-else
                    class="text-center pt-2 pb-2">
                    <div
                        class="fb-ad-preview-body"
                        v-html="ad.body" />
                </div>
            </styled-card>
        </template>
        <div
            v-else-if="ad"
            class="ad-preview-container">
            <div class="ad-preview-container-inner">
                <ad-preview
                    :ad-data="ad"
                    :management-preview="true" />
                <action-button
                    class="refresh-button"
                    icon="refresh"
                    @click="handleRefresh" />
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import Icon from '@/components/globals/Icon';
import ActionButton from '@/components/globals/ActionButton';
import StyledCard from '@/components/globals/StyledCard';
import StyledLoader from '@/components/globals/StyledLoader';
import AdPreview from '@/components/globals/AdPreview';
import AdPlaceholder from '@/components/globals/AdPreview/AdPlaceholder';
import { FACEBOOK_AD_PLACEMENTS } from '@/helpers/globals';

const maxFbWidth = Math.max(
    ...FACEBOOK_AD_PLACEMENTS.map(({ iframeWidth }) => iframeWidth)
);

export default {
    components: {
        Icon,
        StyledCard,
        AdPreview,
        AdPlaceholder,
        StyledLoader,
        ActionButton
    },
    props: {
        adAccountId: {
            type: [Number, String],
            required: true
        },
        adId: {
            type: [Number, String],
            required: true
        },
        channel: {
            type: String,
            required: true
        },
        placement: {
            type: String,
            required: true
        },
        adStatus: {
            type: String,
            required: true
        },
        dealerId: {
            type: Number,
            required: true
        },
        agencyId: {
            type: Number,
            required: true
        },
        adPreviewKey: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            maxFbWidth,
            hasIntersected: false,
            loading: false,
            loaded: false,
            ad: null,
            error: '',
            currentFbPlacement: null,
            FACEBOOK_AD_PLACEMENTS,
        };
    },
    computed: {
        isDisplayed() {
            if (this.adStatus && this.ad) {
                return this.ad.status === this.adStatus;
            }

            return true;
        },
        currentFbPlacementData() {
            return FACEBOOK_AD_PLACEMENTS.find(placement => {
                return this.currentFbPlacement === placement.apiName;
            });
        }
    },
    watch: {
        placement: {
            immediate: true,
            handler() {
                this.currentFbPlacement = this.placement;
            }
        },
        currentFbPlacement(newPlacement, placement) {
            if (placement !== null &&
                newPlacement !== placement &&
                this.channel === 'facebook') {
                this.getFacebookAd();
            }
        }
    },
    methods: {
        onIntersect() {
            if (!this.hasIntersected) {
                this.getAd();
            }
            this.hasIntersected = true;
        },
        getAd() {
            if(this.channel === 'facebook') {
                this.getFacebookAd();
            } else {
                this.getStandardAd();
            }
        },
        async getStandardAd(reset = false) {
            try {
                this.loading = true;
                this.error = '';

                const response = await this.$apiRepository.getDealerPlatformAdPreview({
                    dealerId: this.dealerId,
                    platform: this.channel,
                    adId: this.adId,
                    key: this.adPreviewKey,
                    preview: true,
                    reset
                });

                this.ad = response.data;
                this.loaded = true;
            } catch(error) {
                this.error =
                `<p>
                    There was a problem retrieving this ad ${this.adId} from ${this.channel}.
                </p>
                <p>
                    Please try refreshing the page or <a href="mailto:support@buyerbridge.io">contact support</a> if the problem continues.
                </p>`;
                console.log(this.error, error.response || error);
            } finally {
                this.loading = false;
            }
        },
        async getFacebookAd() {
            try {
                this.loading = true;
                this.error = '';

                const {
                    iframeHeight: height,
                    iframeWidth: width
                } = this.currentFbPlacementData;

                const response = await axios.post(process.env.VUE_APP_MVP_API_PUBLIC_BASE_URI + '/facebook/ad-account/previews', {
                    agency_id: this.agencyId,
                    ad_account: this.adAccountId,
                    ad_format: this.currentFbPlacement,
                    ad_ids: this.adId,
                    width,
                    height
                });

                this.ad = response.data[0];
                this.loaded = true;
            } catch(error) {
                this.error =
                `<p>
                    There was a problem retrieving this ad ${this.adId} from ${this.channel}.
                </p>
                <p>
                    Please try refreshing the page or <a href="mailto:support@buyerbridge.io">contact support</a> if the problem continues.
                </p>`;
                console.log(this.error, error.response || error);
            } finally {
                this.loading = false;
            }
        },
        async handleRefresh() {
            this.loaded = false;
            await this.getStandardAd(true);
            this.true = false;
        }
    }
};
</script>

<style lang="scss" scoped>

.ad-preview-container {
    margin: 30px;
    min-width: 375px;
    min-height: 591px;
    border-radius: 20px;
    vertical-align: top;
    margin-bottom: 100px;
    @media only screen and (max-width: 400px) {
       margin:30px 15px 60px 15px;
       min-width: 320px;
       min-height: 568px;
       margin-bottom: 100px;
    }
}

.refresh-button {
    position: absolute;
    top: calc(100% + 10px);
}

.ad-preview-container-inner {
    position: relative;
}

.fb-ad-preview-container {
    margin: 10px;
}

.fb-ad-preview-body {
    display: inline-block;
    width: calc(var(--iframe-width) * 0.7);
    height: calc(var(--iframe-height) * 0.7);
    overflow: hidden;
    @media (min-width: $bp-sm) {
        width: auto;
        height: auto;
    }
}
</style>

<style lang="scss">
/* FB writes these in so they need to be in the global scope */
.fb-ad-preview-body iframe {
    transform: scale(0.7);
    transform-origin: 0 0;
    @media (min-width: $bp-sm) {
        transform: scale(1);
    }
}
</style>