var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isDisplayed),expression:"isDisplayed"},{name:"intersect",rawName:"v-intersect",value:(_vm.onIntersect),expression:"onIntersect"}],style:({
        '--iframe-width': `${_vm.currentFbPlacementData.iframeWidth}px`,
        '--iframe-height': `${_vm.currentFbPlacementData.iframeHeight}px`,
        maxWidth: (_vm.channel === 'facebook') ? `${_vm.maxFbWidth + 40}px` : 'none'
    })},[(_vm.loading && !_vm.loaded)?_c('div',{staticClass:"pa-5 text-xs-center"},[_c('styled-loader',{attrs:{"size":20}})],1):(_vm.error)?_c('div',{staticClass:"my-5"},[_c('ad-placeholder',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.error)}})])],1):(_vm.ad && _vm.channel === 'facebook')?[_c('styled-card',{staticClass:"fb-ad-preview-container",attrs:{"hide-toggle":"","simple":""},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._v(" "+_vm._s(_vm.ad.name)+" "),_c('span',{staticClass:"circle ml-1",class:{
                        'active': _vm.ad.status === 'ACTIVE'
                    }})]},proxy:true},{key:"action-buttons",fn:function(){return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({},on),[_c('icon',{attrs:{"size":"20","name":"list"}})],1)]}}])},[_c('v-list',_vm._l((_vm.FACEBOOK_AD_PLACEMENTS),function(fbPlacement){return _c('v-list-tile',{key:fbPlacement.apiName,on:{"click":function($event){_vm.currentFbPlacement = fbPlacement.apiName}}},[_c('v-list-tile-title',[_vm._v(_vm._s(fbPlacement.name))])],1)}),1)],1)]},proxy:true}])},[(_vm.loading && _vm.loaded)?_c('div',{staticClass:"pa-5 text-xs-center"},[_c('styled-loader',{attrs:{"size":20}})],1):_c('div',{staticClass:"text-center pt-2 pb-2"},[_c('div',{staticClass:"fb-ad-preview-body",domProps:{"innerHTML":_vm._s(_vm.ad.body)}})])])]:(_vm.ad)?_c('div',{staticClass:"ad-preview-container"},[_c('div',{staticClass:"ad-preview-container-inner"},[_c('ad-preview',{attrs:{"ad-data":_vm.ad,"management-preview":true}}),_c('action-button',{staticClass:"refresh-button",attrs:{"icon":"refresh"},on:{"click":_vm.handleRefresh}})],1)]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }