import updateQuery from 'update-query';

export default {
    methods: {
        updateQuery(obj) {
            const $router = this.$router,
            $route = this.$route;
            $router.push.call($router,
                updateQuery($route.fullPath, obj)
                );
            }
        }
    };