var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.onIntersect),expression:"onIntersect"}],staticClass:"ad-set-previews"},[_c('div',{staticClass:"camp"},[_c('div',{staticClass:"camp__heading"},[(!_vm.campaign && !_vm.campaignError)?_c('div',{},[_c('styled-loader',{attrs:{"size":20}})],1):(_vm.campaignError)?_c('div',{staticClass:"camp_error"},[_vm._v(" "+_vm._s(_vm.campaignError)+" ")]):_c('div',{staticClass:"camp__holder"},[_c('div',{staticClass:"camp__icon"},[_c('icon',{attrs:{"size":"20","name":"folder"}})],1),_c('div',{staticClass:"camp__text"},[_c('div',{staticClass:"camp__subtitle"},[_vm._v(" Campaign: ")]),_c('div',{staticClass:"camp__desc"},[_vm._v(" "+_vm._s(_vm.campaign.name)+" ")])]),_c('div',{staticClass:"camp__active"},[_c('span',{staticClass:"circle",class:{
                            'active': _vm.campaign.status === 'ACTIVE'
                        }})])]),_c('div',{staticClass:"camp__divider"},[_c('icon',{attrs:{"size":"16","color":"blue","name":"chevron-right"}})],1),(_vm.loadingAdSet)?_c('div',{},[_c('styled-loader',{attrs:{"size":20}})],1):(_vm.error)?_c('div',{staticClass:"camp_error"},[_vm._v(" "+_vm._s(_vm.error)+" ")]):(_vm.activeAdSet)?_c('div',{staticClass:"camp__holder"},[_c('div',{staticClass:"camp__icon"},[_c('icon',{attrs:{"size":"20","name":"adset"}})],1),_c('div',{staticClass:"camp__text"},[_c('div',{staticClass:"camp__subtitle"},[_vm._v(" Adset: ")]),_c('div',{staticClass:"camp__desc"},[_vm._v(" "+_vm._s(_vm.activeAdSet.name)+" ")])]),_c('div',{staticClass:"camp__active"},[_c('span',{staticClass:"circle",class:{
                            'active': _vm.activeAdSet.status === 'ACTIVE'
                        }})])]):_vm._e()])]),_c('div',{class:[
            'preview-wrapper',
            `preview-wrapper-${_vm.channel}`
        ]},_vm._l((_vm.activeAds),function(ad){return _c('ad',{key:ad.external_id,class:[
                'ad-preview',
                `ad-preview-${_vm.channel}`
            ],attrs:{"ad-account-id":_vm.adAccountId,"ad-id":ad.external_id,"channel":_vm.channel,"placement":_vm.placement,"ad-status":_vm.adStatus,"dealer-id":_vm.dealerId,"agency-id":_vm.agencyId,"ad-preview-key":_vm.adPreviewKey}})}),1),(!_vm.ads)?[(_vm.loadingAdsPage)?_c('div',{staticClass:"pa-5 text-xs-center"},[_c('styled-loader',{attrs:{"size":30,"label":"Loading next ads page..."}})],1):_vm._e(),(Boolean(_vm.nextPage))?_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.onNextPage),expression:"onNextPage"}],key:_vm.loadedAds.length,staticClass:"next-page"}):_vm._e(),(_vm.loadedAds.length === 0)?_c('div',{class:[
                'ad-preview-placeholder',
                'ad-preview',
                `ad-preview-${_vm.channel}`
            ]}):_vm._e()]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }