<template>
    <v-menu
        v-model="menu"
        :close-on-content-click="false"
        lazy
        transition="scale-transition"
        offset-y
        full-width
        min-width="290px">
        <template #activator="{ on }">
            <v-text-field
                :value="displayedValue"
                :label="label"
                class="styled-field mb-0"
                placeholder="Select..."
                readonly
                v-on="on" />
        </template>
        <div class="selector-dropdown">
            <styled-checkbox
                label="All"
                checkmark-scale="0.8"
                :checked="Boolean(value.length)"
                :dash="value.length !== options.length"
                square
                inline
                @update:checked="handleAllChecked" />
            <styled-checkboxes
                :value="value"
                class="selector-platforms"
                :items="options"
                checkmark-scale="0.8"
                square
                inline
                @input="$emit('input', $event)" />
        </div>
    </v-menu>
</template>

<script>
import StyledCheckbox from '@/components/globals/StyledCheckbox';
import StyledCheckboxes from '@/components/globals/StyledCheckboxes';

export default {
    components: {
        StyledCheckbox,
        StyledCheckboxes
    },
    props: {
        value: {
            type: Array,
            required: true
        },
        options: {
            type: Array,
            required: true
        },
        label: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            menu: false,
        };
    },
    computed: {
        displayedValue() {
            if (this.options.length === this.value.length) {
                return 'All';
            } else if (this.value.length === 1) {
                return this.options.find(({ value }) => (
                    this.value[0] === value
                ))?.text || '';

            } else if (this.value.length) {
                return 'Multiple';
            }
            return '';
        },
    },
    methods: {
        handleAllChecked(isChecked) {
            if (isChecked) {
                const allValues = this.options.map(option => (
                    option.value
                ));
                this.$emit('input', allValues);
            } else {
                this.$emit('input', []);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.selector-platforms {
    margin-left: 20px;
}
.selector-dropdown {
    background-color: $white;
    padding: 10px;
    text-transform: uppercase;
}
</style>